import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const ContentHtmlLoadable = loadable(() => import('./ContentHtml'), {
  resolveComponent: components => components.ContentHtml
});

export const ContentHtml = WithLazyHydration(ContentHtmlLoadable, {
  name: 'ContentHtml'
});
